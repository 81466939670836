(function ($) {
  // Document ready
  $(function () {
    // Ajax form submit / Аякс форма настраивается тут
    $(".ajax-contact-form").on("submit", function (e) {
      e.preventDefault();
      const url = $(this).attr("action");
      const method = $(this).attr("method");
      const dataType = $(this).data("type") || null;
      const serializedArray = $(this).serializeArray();
      const self = $(this);

      let requestObj = {};
      serializedArray.forEach((item) => {
        requestObj[item.name] = item.value;
      });

      $(".form-success-message").addClass("d-none");

      $.ajax({
        url,
        type: method,
        dataType: dataType,
        data: {
          ...requestObj,
        },
        success: function (data) {
          // Handle uncaught server error
          if (data !== "Email sent") {
            alert("Error, try again later");
            console.error(data);
            return;
          }

          // Clear inputs
          self
            .find(
              "input[type='text'], input[type='number'], input[type='tel'], input[type='email'], input[type='password'], textarea",
            )
            .val("");

          // Success message
          self.find(".form-success-message").removeClass("d-none");
        },
        error: function (data) {
          // Basic error handling
          alert("Error, try again later");
          console.error(data);
        },
      });
    });

    // Init AOS
    AOS.init({
      duration: 600,
      once: true,
    });

    // lazy loads elements with default selector as '.lozad'
    const lozad_observer = lozad(".lozad", {});
    lozad_observer.observe();

    let w = $(window).outerWidth(true);

    if (w <= 576) {
      $(".lozad").each(function () {
        let full = $(this).data("backgroundImageFull");
        let mobile = $(this).data("backgroundImageMobile");
        if (full && mobile) {
          $(this).attr("data-background-image", mobile);
        }
      });
    } else {
      $(".lozad").each(function () {
        let full = $(this).data("backgroundImageFull");
        let mobile = $(this).data("backgroundImageMobile");
        if (full && mobile) {
          $(this).attr("data-background-image", full);
        }
      });
    }
    $(window).on("resize", function () {
      let w = $(window).outerWidth(true);

      if (w <= 576) {
        $(".lozad").each(function () {
          let full = $(this).data("backgroundImageFull");
          let mobile = $(this).data("backgroundImageMobile");
          if (full && mobile) {
            $(this).attr("data-background-image", mobile);
            $(this).css("background-image", `url(${mobile})`);
          }
        });
      } else {
        $(".lozad").each(function () {
          let full = $(this).data("backgroundImageFull");
          let mobile = $(this).data("backgroundImageMobile");
          if (full && mobile) {
            $(this).attr("data-background-image", full);
            $(this).css("background-image", `url(${full})`);
          }
        });
      }
    });

    // On page scroll
    // Sticky header
    if ($(".main-header").length) {
      let sticky = $(".main-header").outerHeight(true);

      if (window.pageYOffset > sticky) {
        $(".main-header").addClass("sticky");
        $(".main-content").css({
          "padding-top": `${sticky}px`,
        });
      } else {
        $(".main-header").removeClass("sticky");
        $(".main-content").css({
          "padding-top": "",
        });
      }
    }
    $(document).on("scroll", function () {
      // Sticky header
      if ($(".main-header").length) {
        let sticky = $(".main-header").outerHeight(true);

        if (window.pageYOffset > sticky) {
          $(".main-header").addClass("sticky");
          $(".main-content").css({
            "padding-top": `${sticky}px`,
          });
        } else {
          $(".main-header").removeClass("sticky");
          $(".main-content").css({
            "padding-top": "",
          });
        }
      }
    });

    // Animated number counter
    $(".animated-counter").each(function () {
      if ($(this).hasClass("animated")) {
        return;
      }
      if (isInViewport($(this)[0])) {
        var $this = $(this),
          countTo = $this.attr("data-count");

        $this.addClass("animated");

        $({
          countNum: $this.text(),
        }).animate(
          {
            countNum: countTo,
          },

          {
            duration: 1500,
            easing: "linear",
            step: function () {
              $this.text(commaSeparateNumber(Math.floor(this.countNum)));
            },
            complete: function () {
              $this.text(commaSeparateNumber(this.countNum));
              //alert('finished');
            },
          },
        );
      }
    });
    $(window).on("scroll", function () {
      if ($(".animated-counter").length) {
        $(".animated-counter").each(function () {
          if ($(this).hasClass("animated")) {
            return;
          }
          if (isInViewport($(this)[0])) {
            var $this = $(this),
              countTo = $this.attr("data-count");

            $this.addClass("animated");

            $({
              countNum: $this.text(),
            }).animate(
              {
                countNum: countTo,
              },

              {
                duration: 1500,
                easing: "linear",
                step: function () {
                  $this.text(commaSeparateNumber(Math.floor(this.countNum)));
                },
                complete: function () {
                  $this.text(commaSeparateNumber(this.countNum));
                  //alert('finished');
                },
              },
            );
          }
        });
      }
    });

    function commaSeparateNumber(val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  });
})(jQuery);

const isInViewport = function (ele) {
  const rect = ele.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
